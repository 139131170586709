import { CompanyTag } from 'src/application/theming/companyConfigs/types';
import { AuthProvider, type Configuration } from 'src/config/type';
export const prodAppConfig: Configuration = {
  authentication: {
    isEnabled: true,
    defaultProvider: AuthProvider.CIAM,
    providers: [
      {
        name: AuthProvider.CIAM,
        authBaseUrl: 'https://account.${DOMAIN}/services/oauth2/authorize',
        tokenBaseUrl: 'https://account.${DOMAIN}/services/oauth2/token',
        logoutBaseUrl:
          'https://account.${DOMAIN}/secur/logout.jsp?retUrl=/apex/CIAM_SmartExtranetLogout?rls=0',
        clientId:
          '3MVG9TSaZ8P6zP1oFE0hIlrNSo4CRiiQocr4zu68byBZqIiKkFveENbubhtd7v3fHVPCvTAmBgxzNwzwWTGKl',
        mfaRegisterUrl: 'https://account.${DOMAIN}/s/2fa-registration?appId=${APP_ID}',
        redirectUrl: 'https://smexnet.${DOMAIN}/oauth/return/default',
        scope: 'openid profile refresh_token',
        refreshTokenLifetimeMilliseconds: 86_100_000,
      },
      {
        name: AuthProvider.AAD,
        authBaseUrl:
          'https://login.microsoftonline.com/b914a242-e718-443b-a47c-6b4c649d8c0a/oauth2/v2.0/authorize',
        tokenBaseUrl:
          'https://login.microsoftonline.com/b914a242-e718-443b-a47c-6b4c649d8c0a/oauth2/v2.0/token',
        logoutBaseUrl: '/',
        clientId: '0af965e8-5d6b-45ab-ba3b-f107d565557f',
        redirectUrl: 'https://smexnet.${DOMAIN}/oauth/return/default',
        scope: 'openid profile email User.Read',
        refreshTokenLifetimeMilliseconds: 86_100_000,
      },
    ],
  },
  maintenanceEnabled: true,
  maintenanceExcludedCompanies: [
    CompanyTag.AVA,
    CompanyTag.BAGE,
    CompanyTag.BAGN,
    CompanyTag.EDIS,
    CompanyTag.EVNG,
    CompanyTag.ENG,
    CompanyTag.REGN,
    CompanyTag.LEW,
    CompanyTag.LVN,
    CompanyTag.RWW,
    CompanyTag.TNNG,
    CompanyTag.VSE,
    CompanyTag.WCON,
    CompanyTag.WEVG,
    CompanyTag.WN,
  ],
  companyTag: CompanyTag.SHNG,
  smartExtranetApi: {
    apiToken: 'USE_OPEN_ID_CONNECT_ACCESS_TOKEN',
    baseUrl: 'https://smex-api.eon.com/api/${COMPANY_TAG}',
    mockedEndpoints: ['getOrderAccountAssignmentData'],
  },
  smartExtranetPublicApi: {
    baseUrl: 'https://smex-api.eon.com/public/${COMPANY_TAG}',
    mockedEndpoints: [],
  },
  googleMapsJavascriptApi: {
    authentication: {
      key: 'AIzaSyBa-QlNgmlDO7gEa2qS-mhoJmAwppfBxCg',
      v: '3.42',
    },
  },
  azureApplicationInsights: {
    instrumentationKey: '228c1149-2678-4399-8dd7-89a8ce98103d',
  },
  recaptchaSiteKey: '6Lfn1EQfAAAAAEcyZP2VJM9ocLzmvHoPJA25SdqI',
  serviceDeskUrl:
    'https://sigma.service-now.com/smart_extranet?id=support_request&sys_id=9585668c1b55d1104af732649b4bcba8',
  sentryLogging: {
    enabled: true,
    dsn: 'https://759f2fb6ad655ee5ff1ea129095d54c0@sentry.eon.com/148',
  },
  newRelicConfiguration: {
    licenseKey: 'NEW_RELIC_LICENSE_KEY',
    applicationId: '538616885',
    accountId: '4490152',
    agentId: '538616885',
    trustKey: '4490152',
  },
};
